<template>
  <div id="app">
    <home />
  </div>
</template>

<script>

import Home from "@v/Home.vue"

export default {
  name: "App",
  components: {
    Home
  }
}
</script>

<style lang="scss">
@import "./styles/app.scss";
</style>
