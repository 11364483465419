import Vue from "vue";
import App from "./App.vue";
import { setupPlugins, setupComponents } from "@p/setup";
import filters from "./filters";

import 'vue-select/dist/vue-select.css';

setupPlugins(Vue);
setupComponents(Vue);

Vue.filter("normalDate", filters.normalDate);

Vue.config.productionTip = false;

const initApp = () => {
  new Vue({
    render: (h) => h(App),
  }).$mount("#app");
}

initApp()
