<template>
  <b-navbar toggleable="lg" type="dark" variant="primary">
    <slot name="back_btn">
      <button class="back_btn">
        <i class="fa fa-chevron-left"></i>
      </button>
    </slot>

    <b-navbar-brand :to="{ name: 'home' }">
      <span class="d-inline-flex align-items-center">
        <img src="@/assets/logo.png" alt="RVE Logo" height="68px" class="mr-4" />
        <div>
          <div class="mb-1">RVE Intranet</div>

        </div>
      </span>
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>

      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <!-- Inhalte rechts -->

        <b-nav-item-dropdown right text="Benutzer">
          <!-- Using 'button-content' slot -->
          <template #button-content>
            <span class="d-inline-flex align-items-center">
              <b-icon icon="person-circle" class="mr-2" />
              <span>{{ $store.getters["session/username"] }}</span>
            </span>
          </template>
          <b-dropdown-item :to="{ name: 'profil' }">Profil</b-dropdown-item>
          <b-dropdown-item @click="logout">Abmelden</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
//import { mapGetters } from "vuex";

export default {
  name: "NavBar",
  props: {

  },
  methods: {

  },
  computed: {
    /* ...mapGetters("tickets", [
         "allTickets",
         "normalTickets",
         "prioTickets",
         "overdueTickets"
     ]),*/
  }
};
</script>

