import axios from "axios";

let config = {
  baseURL: ''
}

if (process.env.NODE_ENV === "development") {
  config.auth = {
    username: process.env.VUE_APP_USERNAME,
    password: process.env.VUE_APP_PASSWORD,
  };
}

const apiIntranet = axios.create(config)

export default apiIntranet
